@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("../app/fonts/css/clash-display.css");
@import url("../app/fonts/css/test-schmal.css");
@import url("../app/fonts/css/product-san.css");
@import "styles/header";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-dmsans: "DM Sans", sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: var(--font-dmsans) !important;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.text-heading-1 {
  font-size: 48px !important;
  line-height: 60px !important;
  font-weight: 700 !important;
}
.text-heading-2 {
  font-size: 36px !important;
  line-height: 40px !important;
  font-weight: 700 !important;
}
.text-sub-heading {
  font-size: 22px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}
.text-body-medium {
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
}
.text-body-small {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}
.text-main-1 {
  font-family: TestSchmalItalic-Bold !important;
  font-size: 220px !important;
  font-weight: 500 !important;
  line-height: 264px !important;
  letter-spacing: 0.05em !important;
  text-align: center !important;
  color: white !important;
  @media screen and (max-width: 768px) {
    font-size: 107px !important;
    font-weight: 500 !important;
    line-height: 1.4 !important;
  }
}

.text-main-2 {
  font-size: 96px !important;
  line-height: 144px !important;
  font-weight: 700 !important;
  font-family: ClashDisplay-Variable !important;
  @media screen and (max-width: 768px) {
    font-size: 63px !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
    font-family: ClashDisplay-Variable !important;
  }
}
.text-test-schmal-semibol {
  font-family: TestSchmal-Semibol !important;
}

.text-test-schmal-bold {
  font-family: TestSchmalItalic-Bold !important;
}

.text-product-1 {
  font-family: ProductSans-Bold !important;
}

.text-product-2 {
  font-family: ProductSans-Regular !important;
}

.main-hero {
  background-image: url("/hero-bg.png");
  height: 888px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.service-bg {
  background-image: url("/service-bg.png");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.overlay-customer {
  position: absolute;
  height: 218px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #1e1e1e 0%, rgba(30, 30, 30, 0) 100%);
  margin: -2px 0;
}

.comment-wrap {
  width: 100%;
  columns: 4;
  column-gap: 23px;
  & .comment {
    margin-bottom: 23px;
    break-inside: avoid;
  }

  @media screen and (max-width: 768px) {
    columns: 1;
    padding: 0px 12px;
  }
}

.slick-list {
  padding: 0px 60px !important;
  @media screen and (max-width: 768px) {
    padding: unset !important;
  }
}

.slick-slide {
  /* spacing in between each slide */
  margin: 0 -10px !important;
  @media screen and (max-width: 768px) {
    margin: unset !important;
  }
}

.meter {
  height: 4px;
  position: relative;
  background: #d9d9d9;
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.progress {
  background-color: #1e1e1e;
  -webkit-animation: progressBar 3s ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar 3s ease-in-out;
  -moz-animation-fill-mode: both;
}

.image-blog:hover {
  background: rgba(0, 0, 0, 0.45);
}

.footer-contact {
  background: linear-gradient(to left, transparent 50%, #1c0578 50%) right;
  background-size: 200%;
  transition: 0.3s linear;
  &:hover {
    background-position: left;
  }
}

.FQA {
  & .ant-space-item {
    position: relative;
  }
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
