@font-face {
  font-family: TestSchmalItalic-Bold;
  font-weight: 700;
  src: url("../fonts/TestSöhneSchmal-HalbfettKursiv.otf") format("opentype");
}

@font-face {
  font-family: TestSchmal-Semibol;
  font-weight: 600;
  src: url("../fonts/TestSöhneSchmal-Halbfett.otf") format("opentype");
}