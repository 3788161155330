@font-face {
  font-family: "ProductSans-Regular";
  src: url("../fonts/ProductSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ProductSans-Itaic";
  src: url("../fonts/ProductSans-Italic.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ProductSans-BoldItalic";
  src: url("../fonts/ProductSans-BoldItalic.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ProductSans-Bold";
  src: url("../fonts/ProductSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
