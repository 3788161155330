.header {
  position: fixed;
  top: 24px;
  left: 50px;
  right: 50px;
  height: 80px;
  padding: 8px 36px;
  border-radius: 70px;
  border: 0px solid transparent;
  background: linear-gradient(113.45deg, rgba(183, 183, 183, 0.98) 0%, rgba(108, 108, 108, 0.98) 92.19%);
  z-index: 9999;
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -4px;
    background-image: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: inherit;
  }
  @media screen and (max-width: 1370px) {
    display: none !important;
  }
}
.nav-link {
  transition: all ease-in-out 300ms;
  &:hover {
    background-color: #ffffff59;
  }
}

.mobile-memu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  @media screen and (min-width: 1370px) {
    display: none;
  }
}
